import { Footer } from '@/components/organisms/footer/Footer';
import { Navbar } from '@/components/organisms/navbar/Navbar';
import { NavbarPersona } from '@/components/organisms/navbar/NavbarPersona';
import { useRouter } from 'next/router';
import { NavbarBackGround } from '@/components/organisms/navbar/NavbarBackground';

interface TemplateProps {
  children: React.ReactNode;
}

export const Template = (props: TemplateProps) => {
  const { children } = props;
  const router = useRouter();
  const currentPath = router.pathname;

  return (
    <div className="flex flex-col min-h-screen relative">
      <div className="relative h-[7.5rem]">
        <div className="absolute w-full -z-50 translate-y-[-1.5rem]">
          <NavbarBackGround />
        </div>
        <div className="z-10">
          {currentPath === '/verify' ? <NavbarPersona /> : <Navbar />}
        </div>
      </div>

      <main className="flex-grow">{children}</main>
      <div className="z-20">
        <Footer />
      </div>
    </div>
  );
};
