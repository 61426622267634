import { HeroPdfStatus } from '@/components/molecules/signature/HeroPdfStatus';
import { Carousel } from '@/components/molecules/carousel/Carousel';

export const HomePage = () => {
  return (
    <div className="mt-[10rem] w-full mx-auto">
      <div className="-mt-[6.8rem]  mx-auto">
        <HeroPdfStatus />
      </div>
      <div className="mt-10 mb-16">
        <Carousel />
      </div>
    </div>
  );
};
